.roadmaps-search-module {
    margin: 2rem 2rem 0 2rem;
    position: sticky;
    background-color: white;
    padding-bottom: 1rem;

    .is-focused {
        box-shadow: 0px 0px 16px -4px #fff !important;
        input.sk-search-box__text {
            outline: none !important;
            color: black !important;
        }
        input.sk-search-box__text::placeholder {
            opacity: 0.5;
        }
    }
    
    .sk-search-box:focus {
      border: 1px solid var(--peterportal-primary-color-1);
    }

    .sk-search-box {
      border: 1px solid var(--peterportal-primary-color-2);
      background: white !important;
      height: 42px;

      // input.sk-search-box__text:focus {
      //   border: 1px solid var(--peterportal-primary-color-2);
      // }

      input.sk-search-box__text {
        color: var(--peterportal-mid-gray);
        opacity: 1 !important;
      }
      input.sk-search-box__text::placeholder {
        color: var(--peterportal-mid-gray) !important;
      }    
    }

    .search-title {
      text-align: center;
      font-size: 2rem;
      color: white;
    }

    .hit-stats {
      text-align: right;
      font-size: 8pt;
      color: black; 
      margin-top: 0.5rem;
    }

    .sk-selected-filters {
      .ui.blue.label {
        margin: 2px;
      }
    }
}

