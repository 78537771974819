.quarter {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px #2484c6;
  flex: 1 1 30%;
  height: fit-content;
  margin: .75rem;

  .quarter-title {
    color: #202e47;
    font-weight: bold;
    font-size: 20px;
    padding: 1rem 0rem 0rem 2rem;
  }

  .quarter-units {
    padding: 0rem 0rem 1rem 2rem;
    color: #808080;
    font-size: 16px;
  }
}
