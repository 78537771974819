* {
  --zot-blue: #2484c6;
  --aldrich-blue: #74d1f6;
  --ring-road-white: #ffffff;
  --petr-gray: #808080;
}

.year {
  margin: 1rem 0;
  padding: 1rem;
  align-items: center;
  background-color: var(--ring-road-white);
  border-radius: var(--border-radius);
  border-collapse: separate;
  display: inline-block;
  width: 100%;
}

.yearTitleBar {
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-radius);
}

.caret-icon {
  margin-right: 10px;
}

.year-accordion,
.year-accordion-content {
  border-radius: var(--border-radius);
  background-color: var(--ring-road-white);
  display: flex;
  flex-wrap: wrap;
}

.empty-quarter {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px #2484c6;
  flex: 1 1 30%;
  margin: .75rem;
}

.year-accordion-title {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
}

#year-number,
#course-count,
#unit-count {
  font-weight: bold;
}

#year-stats {
  color: var(--petr-gray);
}

#year-title {
  font-size: 1.2rem;
  color: black;
}

#year-accordion-icon {
  display: inline;
  padding-right: 1rem;
}

.year-accordion {
  width: 95%;
  padding-right: 0;
}

.year-accordion:hover,
.year-accordion:active,
.year-accordion:focus {
  text-decoration: none;
  box-shadow: none;
}

.year-accordion-title {
  width: 100%;
}

.edit-btn {
  background-color: var(--ring-road-white);
  width: 5%;
  margin-top: 10px;
}

.edit-btn:hover {
  cursor: pointer;
}

.year-settings-btn {
  display: block;
  width: 100%;
  text-align: start;
  border: 0px;
  background: transparent;
  padding: 0;
}

.edit-year-form-label {
  font-weight: bold;
}

.edit-year-popup-btn {
  text-transform: uppercase;
  background-color: var(--peterportal-primary-color-1);
  color: var(--ring-road-white);
  border-radius: 1.25rem;
}

#clear-btn {
  color: red;
}

#remove-btn {
  color: red;
}

@media only screen and (max-width: 400px) {
  .year-accordion-title {
    flex-direction: column;
  }

  .edit-btn {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .year-accordion-content {
    flex-direction: column;
  }
}