.roadmap-page {
  font-family: 'Open Sans', sans-serif;
  height: calc(100vh -  4rem);
  overflow: hidden;
  display: flex;
  width: 100%;

  .hide {
    display: none;
  }

  .main-wrapper {
    width: 80%;
    height: 100%;
    overflow-y: auto;
  }

  .main-wrapper.mobile {
    width: 100%;
  }

  .sidebar-wrapper {
    background: white;
    border-radius: var(--border-radius);
    margin-left: 1vw;
    width: 25%;
    height: 100%;
    background-color: white;
    overflow-y: auto;
  }

  .sidebar-wrapper.mobile {
    width: 100%;
    margin: 0;
  }
}