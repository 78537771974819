* {
  --zot-blue: #2484c6;
  --aldrich-blue: #74d1f6;
  --ring-road-white: #ffffff;
  --petr-gray: #808080;
}

.header {
  background: linear-gradient(to right, var(--zot-blue), var(--aldrich-blue));
  border-radius: var(--border-radius);
  color: var(--ring-road-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  
  #title {
    font-size: 1.5em;
  }
}

#planner-stats {
  margin-left: 1rem;
  font-size: 1em;
}

#course-count,
#unit-count {
  font-weight: bold;
}

.header-btn {
  color: var(--ring-road-white);
  background-color: transparent;
  font-weight: bold;
  border: none;
}

.header-btn:hover,
.header-btn:focus {
  background-color: transparent;
  color: var(--ring-road-white);
}

.header-icon {
  margin-left: 10px;
}

@media only screen and (max-width: 400px) {
  .header {
    #title {
      font-size: 1em;
    }

    #planner-stats {
      font-size: 0.70em;
    }

    .add-course {
      font-size: .70em;
    }
  }
}
