.search-sidebar {
  position: sticky;
  right: 0;
  top: 0;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .close-icon {
    width: 5vh;
    height: 5vh;
    float: right;
  }

  .search-body {
    flex-grow: 1;
    overflow: hidden;
  }
}

.search {
  padding: 2rem;
}

.search-header {
  display: flex;
}
