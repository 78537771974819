.course {
  box-shadow: 0px 0px 4px #2484C6;
  background: #FFFFFF;
  border-radius: 15px;
  height: auto;
  font-size: 16px;
  padding: 10px 20px;
  position: relative;

  .name {
    font-weight: bold;
    color: #2484C6;
    font-size: 18px;
  }

  .title {
    font-size: 16px;
  }

  .units {
    margin-left: auto;
    color: #808080;
    font-size: 14px;
  }
}

.invalid {
  box-shadow: 0px 0px 4px orange;
}

.course-card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-and-info {
  display: flex;
  gap: 5px;
  align-items: center;
}

.course-delete-btn {
  padding: 0px;
  background-color: transparent;
  font-weight: bold;
  border: none;
}

.course-delete-icon {
  color: red;
}

.footer {
  padding: 0px;
  display: flex;
  justify-content: space-between;
}

.course-popover {
  background: #FFFFFF;
  padding: 5%;

  .popover-name {
    font-weight: bold;
    color: black;
    font-size: 16px;
  }

  .popover-units {
    color: #c4c4c4;

    .popover-units-value {
      font-weight: bold;
    }
  }

  .popover-description {
    padding-top: 5%;
  }

  .popover-detail {
    padding-top: 5%;
  }
  .popover-detail-prefix {
      font-weight: bold;
  }
}